<script setup>
import { ref, onMounted } from "vue";
import Eye from "./Icons/Eye.vue";

const props = defineProps({
    modelValue: String,
    containerClass: [String, null],
    inputClass: [String, null],
    placeholder: [String, null],
    variation: {
        type: String,
        default: "light",
    },
    type: {
        type: String,
        default: "text",
    },
    reset: {
        type: Boolean,
        default: false,
    },
    autocomplete: {
        type: String,
        default: "off",
    },
    noPlaceholder: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        default: "",
    },
    small: {
        type: Boolean,
        default: false,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    maxValue: {
        type: Number,
        default: 0,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    min: {
        // String, number or null
        type: [String, Number, null],
    },
});

const emit = defineEmits(["update:modelValue"]);

const input = ref(null);
const isFocused = ref(false);
const isPasswordVisible = ref(false);

const togglePassword = () => {
    input.value.type = input.value.type === "password" ? "text" : "password";
    isPasswordVisible.value = !isPasswordVisible.value;
    input.value.focus();
};

const handleBlur = () => {
    isFocused.value = false;
};

const handleReset = () => {
    input.value.focus();
    emit("update:modelValue", "");
};

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <div class="grid grid-cols-1">
        <div
            class="input-wrapper rounded-md"
            :class="{
                focused: isFocused,
            }"
        >
            <div
                class="flex items-center border border-light rounded-md overflow-hidden px-6 py-1 relative"
                :class="{
                    ...containerClass,
                    'bg-light ': variation === 'light',
                    'bg-white ': variation === 'white',
                }"
            >
                <div class="flex items-center justify-center w-full">
                    <input
                        :disabled="props.disabled"
                        ref="input"
                        class="flex-1 border-none text-sm bg-transparent w-full placeholder-transparent focus:outline-none active:outline-none focus-within:outline-none focus:ring-0 text-slate-600 disabled:cursor-not-allowed disabled:75"
                        :class="[
                            type === 'range'
                                ? 'h-8'
                                : [
                                      'px-0',
                                      {
                                          ...inputClass,
                                          'h-10 pt-5': small && !noPlaceholder,
                                          'h-8 pt-2': small && noPlaceholder,
                                          'h-12 pt-7': !small && !noPlaceholder,
                                          'h-12 pt-3': !small && noPlaceholder,
                                      },
                                  ],
                        ]"
                        :value="modelValue"
                        :placeholder="placeholder"
                        :type="type"
                        @focus="isFocused = true"
                        @blur="handleBlur"
                        @input="$emit('update:modelValue', $event.target.value)"
                        :autocomplete="autocomplete"
                        :name="name"
                        :autofocus="autofocus"
                        :required="required"
                        :max="maxValue"
                        :min="min"
                    />
                    <span
                        v-if="type === 'range'"
                        class="text-xs text-gray-500 w-10 flex items-center justify-center"
                    >
                        {{ modelValue }}
                    </span>
                </div>
                <label
                    :class="[
                        'absolute transition-all h-auto flex items-center w-full text-sm cursor-text text-nowrap',
                        !isFocused && !modelValue ? 'translate-y-[100%]' : '',
                        !isFocused && modelValue ? '!translate-y-[40%]' : '',
                        modelValue ? 'text-primary' : '',
                        disabled ? '!opacity-70' : '',
                        {
                            'top-0 !translate-y-[80%]': small && !isFocused,
                            '!translate-y-[44%]':
                                !isFocused &&
                                (type == 'date' || type == 'time'),
                            'text-gray-500 top-0 translate-y-[90%]': !isFocused,
                            'text-primary top-2': isFocused,
                            'text-primary !top-1': small && isFocused,
                        },
                    ]"
                    @click="input.focus"
                    v-if="!noPlaceholder"
                >
                    {{ placeholder }}
                </label>
                <template v-if="type === 'password'">
                    <button
                        type="button"
                        @click="togglePassword"
                        class="bg-transparent h-auto w-auto p-1 rounded-sm flex items-center justify-center"
                        :class="
                            isPasswordVisible ? 'text-primary' : 'text-gray-400'
                        "
                    >
                        <Eye />
                    </button>
                </template>

                <template v-if="reset && modelValue">
                    <button
                        type="button"
                        @click="handleReset"
                        class="bg-transparent h-auto w-auto p-1 rounded-sm flex items-center justify-center text-slate-500"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM13 7H11V13H17V11H13V7Z"
                                fill="currentColor"
                            />
                        </svg>
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>
